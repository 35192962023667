import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loading from './component/LoadingLazyComponent/loading';
import './index.css';

const LazyComponent = React.lazy(() => import('./App'));

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <LazyComponent />
  </Suspense>,
  document.getElementById('root'),
);
